import {MetaSwiper} from "./meta-settings.js";
import {CountUp} from "./plugins/countUp.js";
document.addEventListener("DOMContentLoaded", () => {
// fix css dvh property
function fixDvh() {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);
    window.addEventListener("resize", () => {
        let vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty("--vh", `${vh}px`);
    });
}

fixDvh();

window.scrollToEl = function scrollToEl(targetID) {
  let config = [...(targetID.split('|'))];
  let duration = +config[1] && +config[1] != "" ? +config[1] : 800;
  let paddingTop = +config[2] && +config[2] != "" ? +config[2] : 0;
  let target = config[0];
  if ($(target).length) {
    $("html, body").animate({
      scrollTop: $(target).offset().top - paddingTop,
    }, duration);
    return false;
  }
};
/*onclick=scrollToEl("#get-in-touch-section|800|150")*/



let safari12 = navigator.sayswho == "Safari 12";
let safari13 = navigator.sayswho == "Safari 13";
let safari14 = navigator.sayswho == "Safari 14";

if (safari12) $('body').addClass('safari-12');
if (safari13) $('body').addClass('safari-13');

AOS.init({
    duration: 800,
    useClassNames: true,
    once: true,
});

function setAnimationMultiplyDelay(element, startDelay, defaultDelayStep, breakDelay) {
    let targetArr = $(`${element}`);
    let delay = startDelay ? startDelay : 0;
    let delayStep = defaultDelayStep ? defaultDelayStep : 0;
    let counter = 0;
    if (!delayStep) return;
    targetArr.each((idx, el) => {
        if (el) {
            $(el).attr('data-aos-delay', delay);
            delay += defaultDelayStep;
            if (breakDelay) {
                counter++
                if (counter === breakDelay) {
                    delay = 0;
                    counter = 0;
                }
            }
        }
    });
}

const header = document.getElementsByClassName(`header`)[0];
// Header scroll
const scrollContainer = () => {
    return document.documentElement || document.body;
};

if (header.classList.contains("fixed")) {
    document.addEventListener("scroll", () => {
        if (scrollContainer().scrollTop > 0) {
            header.classList.add("scrolled");
        } else if (scrollContainer().scrollTop == 0) {
            header.classList.remove("scrolled");
        }
    });
}

// menu handlers

///check mobile menu show/hide condition
const mobileMenuStartPoint = +getComputedStyle(
    document.documentElement
).getPropertyValue("--mobile-menu-start-point");
let isMobileMenuEnable = $(window).outerWidth() <= mobileMenuStartPoint || $(".header-mobile").length;


// toggle menu handler
function menuToggle() {
    $(".menu-toggle").toggleClass("active");
    $(".navbar-nav").toggleClass("active");
    $(".header-close-wrapper").toggleClass("active");
    // LockScroll when burger open and enable when closed and enable scroll on menu
    if (scrollLock.getScrollState()) {
        scrollLock.disablePageScroll(document.querySelector(".navbar-nav .menu"))
    } else {
        scrollLock.enablePageScroll();
    }
}

$('.menu-item.dropdown > .menu-link').click(function (e) {
    if (!$(this).closest('.dropdown').hasClass('active')) e.preventDefault();
})

$('.dropdown-menu-link-wr .plus').each((idx, el) => {
    $(el).click(function () {
        let parent = $(this).closest('.dropdown');
        let dropdownMenu = parent.find('.dropdown-menu');
        let delay = 200;
        $('.dropdown-menu').slideUp(function () {
            $(this).closest('.dropdown').removeClass('active');
        });
        if (!parent.hasClass('active')) {
            dropdownMenu.find('li').each((idx, li) => {
                delay += 100;
                $(li).css('animation-delay', delay + 'ms');
            })

            setTimeout(function () {
                dropdownMenu.slideDown({
                    start: function () {
                        parent.toggleClass('active');
                    }
                });
            }, 200)
        }

    })
})

// end of toggle menu handler
$(".menu-toggle").click(menuToggle); //menu toggles
$(".header-close-wrapper").click(menuToggle); //menu toggles

var elements = document.querySelectorAll(".force-sticky");
Stickyfill.forceSticky();
Stickyfill.add(elements);


window.setSelect2Items = function setSelect2Items() {
    $(".select2-item").each((ind, el) => {
        let parent = $(el).closest('.select-wr');
        let filtersType = $(el).data('filter');
        let currentAsideInputsParent = document.getElementById(filtersType);
        let startVales = [];

        let select2 = $(el).select2({
            dropdownParent: parent,
        })

        $(el).change('change', function (e) {
            /*customize multiple*/
            if ($(el).attr('multiple')) {
                let optionCount = $(e.currentTarget).find('option:selected').length;
                if ($(el).val() != '' && optionCount > 1) {
                    $(el).parent().find('.select2-placeholder').show();
                    $(el).parent().find('.select2-placeholder').text(`${optionCount} - Items`);
                } else if ($(el).val() == '') {
                    $(el).parent().find('.select2-placeholder').text($(el).data('placeholder'));
                } else {
                    $(el).parent().find('.select2-placeholder').text($(e.currentTarget).find('option:selected').text());
                }
            }

            /* Job search page filters */
            if (currentAsideInputsParent) {
                let checkedItems = $(e.currentTarget).val();
                let current = checkedItems.filter(item => !startVales.includes(item));
                if(current.length == 0){
                    current = startVales.filter(item => !checkedItems.includes(item));
                }
                startVales = checkedItems;
                let currentInput = currentAsideInputsParent.querySelector(`input[value='${current[0]}']`)
                $(currentInput).trigger('click'); // process all ajax function from back to get searching results
            }
        })

        //2x external binding inputs with select2
        if (currentAsideInputsParent) {
            let asideInputs = currentAsideInputsParent.querySelectorAll('input[type="checkbox"]');
            asideInputs.forEach(input => {
                input.addEventListener('change', function () {
                    let values = [];
                    asideInputs.forEach(input => {
                        if (input.checked) {
                            values.push(input.value);
                        }
                    })
                    select2.val(values).trigger('change');
                    console.log('values = ', values)
                    console.log('load')
                })
            })
        }


    });
}

setSelect2Items();

function setCountUp(selector, swiper) {
    const counts = Array.from(document.querySelectorAll(`${selector}`));
    if (counts) {
        const defaultOptions = {
            separator: "",
            enableScrollSpy: true,
            scrollSpyRunOnce: true,
        };

        let idNumber = 1;

        counts.forEach((count) => {
            const id = `count-up-${idNumber}`,
                value = parseFloat(count.innerHTML);

            let optionsFromDataAttr = $(count).data();
            for (const key in optionsFromDataAttr) {
                if (optionsFromDataAttr[key] === "") {
                    optionsFromDataAttr[key] = true;
                }
            }

            count.id = id;
            let countUp = new CountUp(
                id,
                value,
                Object.assign(Object.assign({}, defaultOptions), optionsFromDataAttr),
            );
            idNumber++;
            if (safari12) {
                setTimeout(function () {
                    if (swiper && window.innerHeight + window.scrollY > $(swiper).offset().top) {
                        countUp.start();
                    }
                }, 50);
            }
        });
    }
}

setCountUp('.stats-card-counter');

let smSwiperWr = document.querySelectorAll('.sm-swiper-wr')
smSwiperWr.forEach(el => {
    if (el) {
        let swiper = el.querySelector('.swiper');
        let slidesCount = swiper.querySelectorAll('.swiper-slide').length;
        let swiperInstance = MetaSwiper(swiper, {
            slidesPerView: 2,
            spaceBetween: 16,
            speed: 800,
            autoplay: {
                delay: 5000,
            },
            breakpoints: {
                320: {
                    loop: slidesCount > 1,
                    slidesPerView: 1,
                    spaceBetween: 10,
                },
                769: {
                    slidesPerView: 2,
                    loop: slidesCount > 2,
                }
            },
            on: {
                afterInit() {
                    setAnimationMultiplyDelay('.mission-swiper-wr .mission-card', 0, 150, 4);
                }
            }
        })
    }
})

let missionSwiperWr = document.querySelectorAll('.mission-swiper-wr')
missionSwiperWr.forEach(el => {
    if (el) {
        let swiper = el.querySelector('.swiper');
        /*let nextEl = el.querySelector('.swiper-button-next')
        let prevEl = el.querySelector('.swiper-button-prev')*/

        let slidesCount = swiper.querySelectorAll('.swiper-slide').length;
        let swiperInstance = MetaSwiper(swiper, {
            slidesPerView: 3,
            spaceBetween: 30,
            speed: 800,
            /* navigation: {
                 nextEl: nextEl,
                 prevEl: prevEl,
             },*/
            autoplay: {
                delay: 5000,
            },
            breakpoints: {
                320: {
                    loop: slidesCount > 1,
                    slidesPerView: 1,
                    spaceBetween: 10,
                },
                769: {
                    loop: slidesCount > 2,
                    slidesPerView: 2,
                    spaceBetween: 25,
                },

                1200: {
                    spaceBetween: 25,
                    slidesPerView: 3,
                }
            },
            on: {
                afterInit() {
                    setAnimationMultiplyDelay('.mission-swiper-wr .mission-card', 0, 150, 4);
                }
            }
        })

    }

})

let sectorsMissionSwiperWr = document.querySelectorAll('.sector-mission-swiper-wr')
sectorsMissionSwiperWr.forEach(el => {
    if (el) {
        let swiper = el.querySelector('.swiper');
        /*let nextEl = el.querySelector('.swiper-button-next')
        let prevEl = el.querySelector('.swiper-button-prev')*/

        let slidesCount = swiper.querySelectorAll('.swiper-slide').length;
        let swiperInstance = MetaSwiper(swiper, {
            slidesPerView: 4,
            spaceBetween: 16,
            speed: 800,
            /* navigation: {
                 nextEl: nextEl,
                 prevEl: prevEl,
             },*/
            autoplay: {
                delay: 5000,
            },
            breakpoints: {
                320: {
                    loop: slidesCount > 1,
                    slidesPerView: 1,
                },
                769: {
                    loop: slidesCount > 2,
                    slidesPerView: 2,
                },

                1200: {
                    slidesPerView: 3,
                },
                1350: {
                    slidesPerView: 4,
                }
            },
            on: {
                afterInit() {
                    setAnimationMultiplyDelay('.mission-swiper-wr .mission-card', 0, 150, 4);
                }
            }
        })

    }

})

let latestJobSwiperWr = document.querySelectorAll('.latest-job-swiper-wr')
latestJobSwiperWr.forEach(el => {
    if (el) {
        let swiper = el.querySelector('.swiper');
        /*let nextEl = el.querySelector('.swiper-button-next');
        let prevEl = el.querySelector('.swiper-button-prev');*/
        let pagination = el.querySelector('.swiper-pagination');
        let slidesCount = swiper.querySelectorAll('.swiper-slide').length;
        let swiperInstance = MetaSwiper(swiper, {
            slidesPerView: 'auto',
            spaceBetween: 30,
            loop: false,
            speed: 800,
            autoplay: {
                delay: 5000,
            },
            /*navigation:{
                nextEl: nextEl,
                prevEl: prevEl,
            },*/
            breakpoints: {
                200: {
                    loop: slidesCount > 1,
                    spaceBetween: 10,

                    // slidesPerView: 1,
                },

                700: {
                    // slidesPerView: 1,
                    loop: false,
                    spaceBetween: 15,
                },
                1024: {
                    // slidesPerView: 2,
                    spaceBetween: 30,
                }
            },
            pagination: {
                el: pagination,
                dynamicBullets: true,
                dynamicMainBullets: 1,
                clickable: true,
            },
            on: {
                afterInit() {
                    setAnimationMultiplyDelay('.latest-job-swiper .swiper-slide', 0, 150, 3);
                }
            }
        })
        let options = {
            root: document,
            rootMargin: "0px",
            threshold: 1.0,
        };
        let cb = (entries, observer) => {
            entries.forEach((entry) => {
                if(entry.isIntersecting){
                    swiperInstance.autoplay.start()
                }else{
                    swiperInstance.autoplay.stop()
                }
            });
        };
        let observer = new IntersectionObserver(cb, options);
        observer.observe(swiper);
    }
})

$('a.job-card').on('click', '.btn', function (e) {
    /*prevent link transition to open popup*/
    e.preventDefault();
})

let teamMbSwiperWr = document.querySelectorAll('.team-mb-swiper-wr')
teamMbSwiperWr.forEach(el => {
    if (el) {
        let swiperEl = el.querySelector('.swiper');
        let pagination = el.querySelector('.swiper-pagination');
        let slidesCount = el.querySelectorAll('.swiper-slide').length;
        let swiper = MetaSwiper(swiperEl, {
            slidesPerView: 'auto',
            initialSlide: 1,
            spaceBetween: 16,
            speed: 800,
            threshold: 10,
            autoplay: {
                delay: 5000,
                disableOnInteraction: false,
                pauseOnMouseEnter: true,
            },
            pagination: {
                el: pagination,
                dynamicBullets: true,
                dynamicMainBullets: 1,
                clickable: true,
            },
            on: {
                afterInit: function () {
                    let minHeight = 0;
                    let slideText = swiperEl.querySelectorAll('.swiper-slide .team-mb-card-text');
                    slideText.forEach(el => {
                        minHeight < el.scrollHeight ? minHeight = el.scrollHeight : minHeight = minHeight;
                    })
                    slideText.forEach(el => el.style.minHeight = minHeight + 'px');

                    /* team-mb-cards click effect */
                    let teamMbCards = $('.team-mb-card');
                    teamMbCards.each((idx, card) => {
                        let hiddenText = $(card).find('.team-mb-card-text-hidden');
                        $(card).click(
                            function () {
                                if($(card).hasClass('active')){
                                    $(card).removeClass('active')
                                    hiddenText.stop(true, false).slideUp();
                                }else{
                                    teamMbCards.each((idx, el)=>{
                                        if(el != card){
                                            $(el).removeClass('active');
                                            $(el).find('.team-mb-card-text-hidden').stop(true, false).slideUp();
                                        }
                                    })
                                    $(card).addClass('active')
                                    hiddenText.stop(true, false).slideDown();
                                }
                            }
                        )
                    })
                    /* team-mb-cards click effect end*/
                },
                slideChange: function(){
                    let teamMbCards = $('.team-mb-card');
                    teamMbCards.each((idx, el)=>{
                        if($(el).hasClass('active')){
                            $(el).removeClass('active');
                            $(el).find('.team-mb-card-text-hidden').stop(true, false).slideUp();
                        }
                    })
                }
            }
        })
    }
})



/*Sector cards active state*/
let sectorCards = document.querySelectorAll(".sector-card-wr");
if (sectorCards.length) {
    let cardLogos = document.querySelectorAll(".sector-card img");
    let cardTitles = document.querySelectorAll(".sector-card-title");
    let contentHidden = document.querySelectorAll(".sector-card-content-hidden");
    let sectorsList = document.querySelector(".sectors-list").getBoundingClientRect();
    sectorCards.forEach((card, idx) => {
        card.addEventListener("click", function () {
            let icon = card.querySelector("img");
            let title = card.querySelector(".sector-card-title");
            let cardHiddenContent = card.querySelector(".sector-card-content-hidden");
            let duration = parseFloat(getComputedStyle(card).getPropertyValue("--duration"));
            if (!this.classList.contains("active")) {
                document.querySelector(".sector-card-wr.active")?.classList.remove("active");
                cardLogos.forEach(img => {
                    if (img != icon) {
                        img.style.left = "";
                    }
                });
                cardTitles.forEach(cardTitle => {
                    if (cardTitle != title) {
                        cardTitle.style.left = "";
                    }
                });
                contentHidden.forEach(content => {
                    if (content != cardHiddenContent) {
                        content.style.transition = "";
                        content.style.opacity = "";
                    }
                });
                if(icon){
                    icon.style.left = (icon.getBoundingClientRect().left - card.getBoundingClientRect().left) + icon.offsetWidth / 2 + "px";
                }
                title.style.left = (title.getBoundingClientRect().left - card.getBoundingClientRect().left) + title.offsetWidth / 2 + "px";
                card.classList.add("active");
                cardHiddenContent.querySelector(".sc-hidden-wr").classList.add('scrollbar-hidden');
                setTimeout(function () {
                    cardHiddenContent.style.transition = "0.3s";
                    cardHiddenContent.style.opacity = "1";
                }, duration);
                setTimeout(function () {
                    cardHiddenContent.querySelector(".sc-hidden-wr").classList.remove('scrollbar-hidden');
                }, duration * 4);
            } else {
                card.classList.remove("active");
                cardHiddenContent.style.transition = "";
                cardHiddenContent.style.opacity = 0;
                cardHiddenContent.querySelector(".sc-hidden-wr").classList.add('scrollbar-hidden');
                setTimeout(function () {
                    if(icon){
                        icon.style.left = "";
                    }
                    title.style.left = "";
                    cardHiddenContent.querySelector(".sc-hidden-wr").style.classList.remove('scrollbar-hidden');
                }, duration);
            }
        });
    });
}

/* End of sector cards active state*/

$('.scroll-down-label').click(function (e) {
    e.preventDefault();
    let target = $(this).attr('href')
    $('html, body').animate({
        scrollTop: $(target).offset().top,
    }, 800);
    return false;
});
let mobilePopup = $('#progress-card-mobile-popup');
let progressSwiperWr = document.querySelectorAll('.progress-swiper-wr')
progressSwiperWr.forEach(el => {
    if (el) {
        let swiperEl = el.querySelector('.swiper');
        let pagination = el.closest('.cont').querySelector('.swiper-pagination');
        let swiperInstance = MetaSwiper(swiperEl, {
            slidesPerView: 'auto',
            speed: 800,
            loop: false,
            centeredSlides: true,
            initialSlide: 2,
            threshold: 20,
            autoplay: {
                delay: 5000,
                pauseOnMouseEnter: true,
            },
            pagination: {
                el: pagination,
                dynamicBullets: true,
                dynamicMainBullets: 1,
                clickable: true,
            },
            breakpoints: {
                200: {
                    spaceBetween: 35,
                    initialSlide: 0,
                    centeredSlides: false,
                    autoplay: {
                        delay: 5000,
                        pauseOnMouseEnter: true,
                    },
                },
                641: {
                    spaceBetween: 15,
                    slidesPerView: 3,
                    initialSlide: 1,
                    autoplay: false,
                    centeredSlides: false,
                },
                1101: {
                    spaceBetween: 50,
                    slidesPerView: 'auto',
                    autoplay: false,
                },
                1300: {
                    spaceBetween: 100,
                    autoplay: false,
                },
                1400: {
                    spaceBetween: 130,
                    autoplay: false,
                },
            },
            on: {
                slideChange: function () {
                    hideProgressCardPopup()
                }
            }
        })
    }
})

const progressCards = $('.progress-swiper .swiper-slide');
progressCards.each((idx, card) => {
    $(card).on('click', function () {
        if (!$(this).hasClass('active')) {
            let popup = $(this).find('.progress-card-popup-wr');
            let popupOffsetLeft = popup.offset().left
            let popupWidth = popup.width();
            let contPadding = parseFloat($('.cont').eq(0).css('padding-right'));
            let offsetLeft = popupOffsetLeft + popupWidth + contPadding;
            let isMobile = $(window).width() <= mobileMenuStartPoint;
            let menuToggleWidth = isMobile ? 0 : $('.main-menu').width();

            if (isMobile) {
                mobilePopup.html(popup.html());
                mobilePopup.slideDown(function () {
                    $(this).addClass('active')
                });
            } else {
                $('.progress-swiper .swiper-slide.active .progress-card-popup-wr').css('margin-left', '');
                $('.progress-swiper .swiper-slide.active').removeClass('active');
                $(this).addClass('active');
                if (offsetLeft > $(window).width()) {
                    popup.css('margin-left', -1 * (offsetLeft - $(window).width()) + 'px');
                } else if (popupOffsetLeft < contPadding + menuToggleWidth && popupOffsetLeft >= 0) {
                    let marginLeft = contPadding + menuToggleWidth - popupOffsetLeft + 'px';
                    popup.css('margin-left', marginLeft)
                } else if (popupOffsetLeft < 0) {
                    let marginLeft = Math.abs(popupOffsetLeft) + menuToggleWidth + popupOffsetLeft + 'px';
                    popup.css('margin-left', marginLeft)
                }
            }
        }
    })
})
$('body').on('click', function (e) {
    if (!$(e.target).closest('.progress-card-popup').length && !$(e.target).closest('.progress-card').length) {
        hideProgressCardPopup()
    }

})

function hideProgressCardPopup() {
    $('.progress-swiper .swiper-slide.active .progress-card-popup-wr').css('margin-left', '');
    $('.progress-swiper .swiper-slide.active').removeClass('active');
    mobilePopup.removeClass('active');
    mobilePopup.slideUp();
}

let newsSwiperWr = document.querySelectorAll('.news-swiper-wr')
newsSwiperWr.forEach(el => {
    if (el) {
        let swiperEl = el.querySelector('.swiper');
        let nextEl = el.querySelector('.swiper-button-next')
        let prevEl = el.querySelector('.swiper-button-prev');
        let pagination = el.querySelector('.swiper-pagination');
        let slidesCount = el.querySelectorAll('.swiper-slide').length;
        let swiper = MetaSwiper(swiperEl, {
            slidesPerView: 'auto',
            spaceBetween: 20,
            speed: 800,
            navigation: {
                nextEl: nextEl,
                prevEl: prevEl,
            },
            pagination: {
                el: pagination,
                dynamicBullets: true,
                dynamicMainBullets: 1,
                clickable: true,
            },
            autoplay: {
                delay: 5000,
            },
            breakpoints: {
                200: {
                    loop: slidesCount > 1,
                    spaceBetween: 10,
                },

                641: {
                    loop: false,
                    spaceBetween: 30,
                }
            }
        })

        let options = {
            root: document,
            rootMargin: "0px",
            threshold: 1.0,
        };
        let cb = (entries, observer) => {
            entries.forEach((entry) => {
                if(entry.isIntersecting){
                    swiper.autoplay.start()
                }else{
                    swiper.autoplay.stop()
                }
            });
        };
        let observer = new IntersectionObserver(cb, options);
        observer.observe(swiperEl);
    }
})
const sectorImgHover = $('.sector-img-hover div');
const sectorIcons = $('.hero-sector-icons-home a');

sectorIcons.hover(
    function () {
        let sector = $(this).data('sector').toLowerCase();
        $('.main').addClass('show-hero-hover');
        $(this).addClass('active');
        sectorImgHover.each((idx, el) => {
            if (sector == $(el).data('sector').toLowerCase()) {
                $(el).css('opacity', 1)
            }
        })
    },
    function () {
        sectorImgHover.css('opacity', 0);
        $('.main').removeClass('show-hero-hover');
        sectorIcons.removeClass('active');
    }
)

let videoItems = document.querySelectorAll('.plyr-video-item')
videoItems.forEach(video => {
    let player = new Plyr(video, {
        controls: ['play-large', 'play', 'progress', 'current-time', 'mute', 'volume', 'airplay', 'fullscreen']
    })
    player.once('play', function (event) {
        event.target.classList.add('start-playing')
    })
})

let jtTabs = $('.jt-section-tab h3');
if (jtTabs.length) {
    jtTabs.each((idx, tab) => {
        let tabContent = $(tab).next('.jt-section-tab-content').html();
        let contentContainer = $('#jt-content-container');
        $(tab).on('click', function () {
            jtTabs.removeClass('active');
            $(this).addClass('active');
            contentContainer.fadeOut(150, function () {
                $(this).html(tabContent);
                $(this).fadeIn();
            });

        })
    })
}
/*sdv tabs start*/
let sdvItems = $('.sdv-item');
let sdvBreakpoint = +getComputedStyle(document.documentElement).getPropertyValue("--sdv-mobile-breakpoint");

sdvItems.each((idx, el) => {
    $(el).on('click', function () {
        let currentEl = $(this);
        let duration = parseFloat($(this).css('--tr-duration'))
        let currentContent = currentEl.find('.sdv-item-content');

        if (window.innerWidth > sdvBreakpoint) {
            if ($(this).hasClass('active')) {
                $(this).find('.sdv-item-content').slideUp(duration, function () {
                    $(el).removeClass('active');
                })
            } else {
                sdvItems.each((idx, el) => {
                    if ($(el)[0] != currentEl[0]) {
                        $(el).find('.sdv-item-content').slideUp(duration / 3, function () {
                            $(el).removeClass('active');
                        })
                    }
                })
                currentEl.addClass('active');
                setTimeout(function () {
                    currentContent.slideDown();
                }, duration)
            }
        } else {
            let contentMobile = $('#sdv-item-content-mobile');
            let title = currentEl.find('.sdv-item-title').prop('outerHTML');
            if ($(this).hasClass('active')) {
                contentMobile.slideUp(duration)
                $(el).removeClass('active');
            } else {
                if (contentMobile.is(':visible')) {
                    sdvItems.each((idx, el) => {
                        if ($(el)[0] != currentEl[0]) {
                            $(el).removeClass('active');
                        }
                    })
                    contentMobile.slideUp(duration, function () {
                        contentMobile.html(title + currentContent.html())
                        contentMobile.slideDown(duration);
                    })
                    currentEl.addClass('active');
                } else {
                    currentEl.addClass('active');
                    contentMobile.html(title + currentContent.html()).slideDown();
                }
            }
        }
    })
})

let sdvSwiperWr = document.querySelectorAll(".sdv-swiper-wr");
sdvSwiperWr.forEach((el) => {
    if (el) {
        let swiperInstance;
        let swiper = el.querySelector(".swiper");
        /*let nextEl = el.querySelector(".swiper-button-next");
        let prevEl = el.querySelector(".swiper-button-prev");*/
        let slidesCount = swiper.querySelectorAll(".swiper-slide").length;
        let swiperInstanceInitialized = false;
        $(window).on("resize", function () {
            if (window.innerWidth < sdvBreakpoint && swiperInstanceInitialized === false) {
                swiperInstanceInitialized = true;
                swiperInstance = MetaSwiper(swiper, {
                    slidesPerView: 'auto',
                    spaceBetween: 28,
                    speed: 800,
                });
            } else if (
                window.innerWidth > sdvBreakpoint &&
                swiperInstanceInitialized === true
            ) {
                swiperInstanceInitialized = false;
                swiperInstance.destroy(true, true);
            }
        });
        $(window).trigger("resize");
    }
});
/*sdv tabs end*/
let teamSwiperWr = document.querySelectorAll('.team-swiper-wr')
teamSwiperWr.forEach(el => {
    if (el) {
        let swiperEl = el.querySelector('.swiper');
        let pagination = el.querySelector('.swiper-pagination');
        let slidesCount = el.querySelectorAll('.swiper-slide').length;
        let swiper = MetaSwiper(swiperEl, {
            slidesPerView: 'auto',
            spaceBetween: 16,
            speed: 800,
            threshold: 10,
            autoplay: {
                delay: 5000,
                disableOnInteraction: false,
                pauseOnMouseEnter: false,
            },
            pagination: {
                el: pagination,
                dynamicBullets: true,
                dynamicMainBullets: 1,
                clickable: true,
            },
            breakpoints: {
                320: {
                    loop: slidesCount > 1,
                },
                500: {
                    loop: slidesCount > 2,
                },
                769: {
                    loop: slidesCount > 3,
                }
            },
        })
    }
})
let wwSwiperWr = document.querySelectorAll('.ww-swiper-wr')
wwSwiperWr.forEach(el => {
    if (el) {
        let swiperEl = el.querySelector('.swiper');
        let slidesCount = el.querySelectorAll('.swiper-slide').length;
        let swiper = MetaSwiper(swiperEl, {
            slidesPerView: 3,
            spaceBetween: 0,
            loop: true,
            speed: 800,
            threshold: 10,
            autoplay: {
                delay: 5000,
                disableOnInteraction: false,
                pauseOnMouseEnter: false,
            },
            breakpoints: {
                200: {
                    slidesPerView: 3,
                    loop: slidesCount > 3,
                    spaceBetween: 20,
                },
                1200: {
                    slidesPerView: 4,
                    loop: slidesCount > 4,
                },
                1400:{
                    loop: slidesCount > 5,
                    slidesPerView: 5,
                },
                1800:{
                    loop: slidesCount > 6,
                    slidesPerView: 6,
                }
            },
            on:{
                breakpoint: function () {
                    let breakpoint = this.currentBreakpoint;
                    let loop = this.passedParams.breakpoints[breakpoint].loop;
                    if (loop) {
                        swiperEl.querySelector('.swiper-wrapper').classList.remove('justify-center');
                    } else {
                        swiperEl.querySelector('.swiper-wrapper').classList.add('justify-center');
                    }
                }
            }

        })
    }
});


let files = document.querySelectorAll('[type="file"]');
if (files) {
    files.forEach(file => {
        file.addEventListener('change', function () {
                $(file).next('.upload-status').text(this.files[0].name);
            }
        )
    })
}
/*sdvVertical items start*/
let sdvVerticalItems = $('.sdv-vertical-item-title');
sdvVerticalItems.each((idx, el) => {
    let solutionBoxImages = $(el).closest('section'). find('.sdv-vertical-section-solutions-bg img');

    $(el).click(function () {
        let currentIndex = idx;

        if ($(this).next().is(':visible')) {
            //close current tab if it is opened
            $(this).parent().toggleClass('active')
            $(this).next().slideUp();
            solutionBoxImages.removeClass('active');
        } else {
            sdvVerticalItems.each((index, el) => {
                if (index !== currentIndex) {
                    $(el).parent().removeClass('active');
                    $(el).next().slideUp();
                }
            })
            $(this).parent().toggleClass('active')
            $(this).next().slideDown();
            solutionBoxImages.each((index, img) => {
                if(index === currentIndex){
                    $(img).addClass('active')
                }else{
                    $(img).removeClass('active')
                }
            })
        }

    })


});

/*sdvVertical items end*/
let gallerySwiperWr = document.querySelectorAll('.gallery-swiper-wr')
gallerySwiperWr.forEach(el => {
    if (el) {
        let swiperEl = el.querySelector('.swiper');
        let slidesCount = el.querySelectorAll('.swiper-slide').length;
        let pagination = el.querySelector('.swiper-pagination');
        let swiper = MetaSwiper(swiperEl, {
            slidesPerView: 'auto',
            spaceBetween: 8,
            initialSlide: 1,
            speed: 800,
            threshold: 10,
            pagination: {
                el: pagination,
                dynamicBullets: true,
                dynamicMainBullets: 1,
                clickable: true,
            },
            autoplay: {
                delay: 5000,
                disableOnInteraction: false,
                pauseOnMouseEnter: false,
            },
        })
    }

})

let caseSwiperWr = document.querySelectorAll('.case-studies-swiper-wr')
caseSwiperWr.forEach(el => {
    if (el) {
        let swiperEl = el.querySelector('.swiper');
        let pagination = el.querySelector('.swiper-pagination');
        let slidesCount = el.querySelectorAll('.swiper-slide').length;
        let swiper = MetaSwiper(swiperEl, {
            slidesPerView: 'auto',
            initialSlide: slidesCount > 3 ? 1 : 0,
            loop: false,
            spaceBetween: 30,
            speed: 800,
            threshold: 10,
            pagination: {
                el: pagination,
                dynamicBullets: true,
                dynamicMainBullets: 1,
                clickable: true,
            },
            breakpoints: {
                200: {
                    spaceBetween: 20,
                },
                768: {
                    spaceBetween: 30,
                }
            },
            autoplay: {
                delay: 5000,
                disableOnInteraction: false,
                pauseOnMouseEnter: false,
            }
        })

    }

})

let becomeTabs = $('.become-tabs-item .title-wr');
if (becomeTabs.length) {
    becomeTabs.each((idx, el) => {
        $(el).on('click', function () {
            let $this = $(this);
            let tabItem = $this.parent();
            let duration = 300;
            let currentContent = tabItem.find('.content-hidden');
            let currentContentHtml = tabItem.find('.become-tabs-item-thumbnail-wr').html();
            let targetContent = $('.become-tabs-content-rt');
            let targetContentHtml = $('.become-tabs-content-rt-html');

            becomeTabs.each((idx, el) => {
                if ($this[0] != el) {
                    $(el).parent().find('.content-hidden').slideUp(200, function () {
                        $(el).parent().removeClass('active');
                    })
                }
            })
            if (!tabItem.hasClass('active')) {
                currentContent.slideDown({
                    duration: 300,
                    start: function () {
                        targetContentHtml.fadeOut(100, function () {
                            $(this).html(currentContentHtml);
                            $(this).fadeIn();
                        })
                    },
                    complete: function () {
                        tabItem.addClass('active');
                    }
                })
            }
        })
    })
}

setAnimationMultiplyDelay('.stats-card', 0, 150, 4);
setAnimationMultiplyDelay('.sectors-list .sector-card', 0, 150, 4);
setAnimationMultiplyDelay('.progress-swiper-wr .swiper-slide', 0, 150, 5);
setAnimationMultiplyDelay('.news-swiper-wr .swiper-slide', 0, 150, 3);
setAnimationMultiplyDelay('.partners-card', 0, 150, 4);
setAnimationMultiplyDelay('.journey-section .journey-list-item', 0, 150, 7);
setAnimationMultiplyDelay('.vision-content-list li', 0, 150, 3);
setAnimationMultiplyDelay('.sdv-vertical-item', 0, 150);
setAnimationMultiplyDelay('.team-swiper .swiper-slide', 0, 150, 3);
setAnimationMultiplyDelay('.gallery-swiper .swiper-slide', 0, 150, 3, 6);
setAnimationMultiplyDelay('.ww-swiper .swiper-slide', 0, 150, 6);
setAnimationMultiplyDelay('.case-studies-swiper .swiper-slide', 0, 150, 3);
setAnimationMultiplyDelay('.crc-item', 0, 150, 8);

$(".jobs-box__accardion-title").on("click", function (e) {
    $(this).next().slideToggle();
});

function jobsFilterMenu() {
    const trigger = document.querySelector('.jobs-box__btn');
    const menu = document.querySelector('.jobs-box__filter');
    const closeBtn = document.querySelector('.jobs-box__close-btn');

    trigger.addEventListener('click', () => {
        menu.classList.add('open');
        closeBtn.classList.add('open');
    })
    closeBtn.addEventListener('click', () => {
        menu.classList.remove('open');
        closeBtn.classList.remove('open');
    })
}

if (document.querySelector('.jobs-box__btn')) {
    jobsFilterMenu();
}

/*contact map handler start*/
let mapPinItems = $('.map-pin');
if (mapPinItems.length) {
    let map = $('.contact-map');
    mapPinItems.each((idx, pin) => {
        $(pin).click(function () {
            let currentPin = $(pin);
            let parent = $(pin).parent();
            let popup = parent.find('.map-popup');
            mapPinItems.each((idx, pin) => {
                if (pin != currentPin[0]) {
                    $(pin).parent().removeClass('active');
                }
            })
            if (popup.offset().left + popup.outerWidth() >= window.innerWidth) {
                let trX = (popup.offset().left + popup.outerWidth()) - window.innerWidth + 10;
                popup.css({
                    left: `-${trX}px`,
                    'transform-origin': `${trX}px top`
                })
            }
            parent.toggleClass('active');
            if (parent.hasClass('active')) {
                map.addClass('active')
            } else {
                map.removeClass('active')
            }
        })
    })

    // hide map popup
    $('body').click(function (e) {
        if (!$(e.target).closest('.map-pin-item').length) {
            $('.map-pin-item').removeClass('active')
            map.removeClass('active');
        }
    })
}

});